class Navbar {

  constructor (el) {
    this.el = el
    this.burger = this.el.querySelector('.js-navbar-burger')
    this.closeButton = this.el.querySelector('.js-navbar-close-button')
    this.openLink = this.el.querySelector('.js-navbar-open-link')
    this.initDefault()
  }

  initDefault () {
    this.initEvents()
  }

  initEvents () {
    this.burger.addEventListener( 'click', e => { this.toggle(e) })
    this.closeButton.addEventListener( 'click', e => { this.toggle(e) })
    this.openLink.addEventListener( 'click', e => { this.toggle(e) })
  }

  toggle (e) {
    e.preventDefault()
    this.burger.classList.toggle('active')
    this.el.classList.toggle('active')
  }

}

window.addEventListener('DOMContentLoaded', e => {
  const el = document.querySelector('.js-navbar')
  if (el) { new Navbar(el) }
})