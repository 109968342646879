import $ from 'jquery'
import { validator, validate} from 'jquery-validation'

class Form {
  
  constructor (el) {
    this.el = el
    this.initDefaults()
  }

  initDefaults () {
    this.validate()
  }

  validate () {
    $.validator.addMethod('fnType', function(value, element) {
      return value.match(/^(?:[0-9] ?){6,14}[0-9]$/);
    });

    $(this.el).validate({
      onkeydown: true,
      onclick: false,
      onchange: true,
      highlight: function(element) {
        $(element).parents('.input').addClass('error').removeClass('validated');
      },
      unhighlight: function(element) {
        $(element).parents('.input').removeClass('error').addClass('validated');
      },
      messages: {
        name: {
          required: 'Пустое поле'
        },
        telegram: {
          required: 'Пустое поле'
        },
        email: {
          required: 'Пустое поле',
          email: "Неправильный Email"
        },
        tel: {
          fnType: true,
          required: 'Пустое поле'
        }
      }
    });
  }
}

window.addEventListener('DOMContentLoaded', () => {
  const el = document.querySelectorAll('.js-form')
  if (el) { el.forEach( el => { new Form(el) }) }
})

