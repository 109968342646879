class Popup {

  constructor (el) {
    this.el = el
    this.closeButton = this.el.querySelector('.js-popup-button')
    this.overlay = this.el.querySelector('.js-popup-overlay')
    this.openButton = document.querySelectorAll('.js-open-popup')
    this.initDefault()
  }

  initDefault () {
    this.initEvents()
  }

  initEvents () {
    this.closeButton.addEventListener('click', e => { this.close(e) })
    this.overlay.addEventListener('click', e => { this.close(e) })
    this.openButton.forEach( el => {
      el.addEventListener('click', e => { this.open(e) })
    })
  }

  close (e) {
    e.preventDefault()
    this.el.classList.remove('active')
  }

  open (e) {
    e.preventDefault()
    const popupId = e.currentTarget.getAttribute('href') || e.currentTarget.getAttribute('data-popup-id')
    const popup = document.querySelector(popupId)
    popup.classList.add('active')
  }

}

window.addEventListener('DOMContentLoaded', () => {
  const el = document.querySelectorAll('.js-popup')
  if (el) { el.forEach( el => { new Popup(el) }) }
})