import retinajs from "retinajs";
import SimpleBar from 'simplebar';

retinajs;

window.addEventListener("DOMContentLoaded", () => {

  const scrollContainer = document.querySelectorAll('.js-scrollbar')
  if (scrollContainer) {
    scrollContainer.forEach( (el, i) => {
      new SimpleBar(el)
    })
  }
})

import "./import/modules";
import "./import/components";
import "./collapse";