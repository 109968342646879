import $ from 'jquery'

class Collapse {

  constructor (el) {
    this.el = el
    this.hiddenEL = this.el.querySelector('.js-collapse-hidden')
    this.button = this.el.querySelector('.js-collapse-button')
    this.initDefault()
  }

  initDefault () {
    this.initEvents()
    console.log('123')
  }

  initEvents () {
    this.button.addEventListener('click', e => { this.slideToggle(e) })
  }

  slideToggle (e) {
    e.preventDefault()
    $(this.hiddenEL).slideToggle(300)
    this.button.classList.toggle('active')
  }

}

window.addEventListener("DOMContentLoaded", () => {
  const el = document.querySelector(".js-collapse");
  if ( el ) { new Collapse(el) }
});