import Inputmask from 'inputmask'

class Input {
    constructor(el) {
        this.el = el
        this.input = this.el.querySelector('input')
        this.initDefaults()
    }

    initDefaults () {
        this.initEvents()
        this.onBlur()
        // this.setPhoneMask()
    }

    initEvents () {
        this.input.addEventListener('blur', this.onBlur.bind(this))
        this.input.addEventListener('focus', this.onFocus.bind(this))
        this.el.addEventListener('click',  e => this.setFocus())
        if ( this.el.classList.contains('input_file') ) {
            this.el.addEventListener('change', e => this.showFileName(e) )
        }
    }

    showFileName(e) {
        const container = this.el.querySelector('.input__file-name');
        container.innerHTML = e.target.files[0].name;
        console.log(e.target.files)
    }

    setFocus () {
        this.el.classList.add('focus')
        this.input.focus()
    }

    onFocus () {
        this.el.classList.add('focus')
    }

    onBlur () {
        this.input.value ? this.el.classList.add('focus') : this.el.classList.remove('focus')
    }

    setPhoneMask () {
      if (this.el.classList.contains('input_phone')) {
        this.im = new Inputmask(
          { 
            mask: "999 99-99-99",
            showMaskOnHover: false,
          });
        this.im.mask(this.input);
      }
    }
}

window.addEventListener('DOMContentLoaded', () => {
    const selector = document.querySelectorAll('.input')
    let inputsCollection = []
    if (selector) {
        selector.forEach( (el, i) => {
            inputsCollection[i] = new Input(el)
        })
    }
})
